<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="window px-5 py-16">
          <v-row justify="center"> <h1>Вход</h1></v-row>
          <v-row justify="center">
            <v-col cols="12">
              <v-form ref="form" @submit="submit">
                <v-text-field
                  label="Email"
                  v-model="email"
                  :rules="emailRules"
                  required
                ></v-text-field>

                <v-text-field
                  label="Пароль"
                  v-model="password"
                  min="8"
                  type="password"
                  :rules="passwordRules"
                  counter
                  required
                ></v-text-field>

                <v-row class="mt-4 mx-0" justify="end">
                  <v-btn block dark type="submit" elevation="0" color="orange">
                    Войти
                  </v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="failed" color="error">
      {{ "Не удалось войти" }}
      <template v-slot:action="{ attrs }">
        <v-btn text dark v-bind="attrs" @click="failed = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import loginer from "@/services/loginer";
import { isEmpty } from "@/services/support";

export default {
  name: "Login",
  props: {
    next: {
      type: String,
      default: "/find-tkt",
    },
  },
  data() {
    return {
      failed: false,
      password: "",
      passwordRules: [(v) => !!v || "Пароль обязателен"],
      email: "",
      emailRules: [
        (v) => !!v || "Email обязателен",
        (v) => /.+@.+\..+/.test(v) || "Email некорректный",
      ],
    };
  },
  mounted() {
    if (!isEmpty(this.$store.getters.user)) {
      this.$router.push({ name: this.name ?? "/" });
    }
  },
  methods: {
    async submit(event) {
      event.preventDefault();

      if (this.$refs.form.validate()) {
        let a = await loginer.signIn(this.email, this.password);
        if (a) {
          this.$router.push({ path: this.next ?? "/" });
        } else {
          this.password = "";
          this.failed = true;
        }
      }
    },
  },
};
</script>

<style scoped></style>
